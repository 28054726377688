
    <template>
      <section class="content element-doc">
        <h2>Card 卡片</h2>
<p>将信息聚合在卡片容器中展示。</p>
<h3>基础用法</h3>
<p>包含标题，内容和操作。</p>
<demo-block>
        <div><p>Card 组件包括<code>header</code>和<code>body</code>部分，<code>header</code>部分需要有显式具名 slot 分发，同时也是可选的。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-card class=&quot;box-card&quot;&gt;
    &lt;template v-slot:header&gt;
      &lt;div class=&quot;clearfix&quot;&gt;
        &lt;span&gt;卡片名称1&lt;/span&gt;
        &lt;el-button style=&quot;float: right; padding: 3px 0&quot; type=&quot;text&quot;
          &gt;操作按钮&lt;/el-button
        &gt;
      &lt;/div&gt;
    &lt;/template&gt;
    &lt;div v-for=&quot;o in 4&quot; :key=&quot;o&quot; class=&quot;text item&quot;&gt;{{'列表内容 ' + o }}&lt;/div&gt;
  &lt;/el-card&gt;
&lt;/template&gt;
&lt;style&gt;
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 480px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>简单卡片</h3>
<p>卡片可以只有内容区域。</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-card class=&quot;box-card&quot;&gt;
    &lt;div v-for=&quot;o in 4&quot; :key=&quot;o&quot; class=&quot;text item&quot;&gt;{{'列表内容 ' + o }}&lt;/div&gt;
  &lt;/el-card&gt;
&lt;/template&gt;
&lt;style&gt;
  .text {
    font-size: 14px;
  }

  .item {
    padding: 18px 0;
  }

  .box-card {
    width: 480px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>带图片</h3>
<p>可配置定义更丰富的内容展示。</p>
<demo-block>
        <div><p>配置<code>body-style</code>属性来自定义<code>body</code>部分的<code>style</code>，我们还使用了布局组件。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row&gt;
    &lt;el-col
      :span=&quot;8&quot;
      v-for=&quot;(o, index) in 2&quot;
      :key=&quot;o&quot;
      :offset=&quot;index &gt; 0 ? 2 : 0&quot;
    &gt;
      &lt;el-card :body-style=&quot;{ padding: '0px' }&quot;&gt;
        &lt;img
          src=&quot;https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png&quot;
          class=&quot;image&quot;
        /&gt;
        &lt;div style=&quot;padding: 14px;&quot;&gt;
          &lt;span&gt;好吃的汉堡&lt;/span&gt;
          &lt;div class=&quot;bottom clearfix&quot;&gt;
            &lt;time class=&quot;time&quot;&gt;{{ currentDate }}&lt;/time&gt;
            &lt;el-button type=&quot;text&quot; class=&quot;button&quot;&gt;操作按钮&lt;/el-button&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/el-card&gt;
    &lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;style&gt;
  .time {
    font-size: 13px;
    color: #999;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }

  .clearfix:after {
    clear: both;
  }
&lt;/style&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      let currentDate = ref(new Date())
      return { currentDate }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>卡片阴影</h3>
<p>可对阴影的显示进行配置。</p>
<demo-block>
        <div><p>通过<code>shadow</code>属性设置卡片阴影出现的时机：<code>always</code>、<code>hover</code>或<code>never</code>。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row :gutter=&quot;12&quot;&gt;
    &lt;el-col :span=&quot;8&quot;&gt;
      &lt;el-card shadow=&quot;always&quot;&gt; 总是显示 &lt;/el-card&gt;
    &lt;/el-col&gt;
    &lt;el-col :span=&quot;8&quot;&gt;
      &lt;el-card shadow=&quot;hover&quot;&gt; 鼠标悬浮时显示 &lt;/el-card&gt;
    &lt;/el-col&gt;
    &lt;el-col :span=&quot;8&quot;&gt;
      &lt;el-card shadow=&quot;never&quot;&gt; 从不显示 &lt;/el-card&gt;
    &lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>header</td>
<td>设置 header，也可以通过 <code>slot#header</code> 传入 DOM</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>body-style</td>
<td>设置 body 的样式</td>
<td>object</td>
<td>—</td>
<td>{ padding: '20px' }</td>
</tr>
<tr>
<td>shadow</td>
<td>设置阴影显示时机</td>
<td>string</td>
<td>always / hover / never</td>
<td>always</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createBlock(_component_el_card, { class: "box-card" }, {
    header: _withCtx(() => [
      _createVNode("div", { class: "clearfix" }, [
        _createVNode("span", null, "卡片名称1"),
        _createVNode(_component_el_button, {
          style: {"float":"right","padding":"3px 0"},
          type: "text"
        }, {
          default: _withCtx(() => [
            _createTextVNode("操作按钮")
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_Fragment, null, _renderList(4, (o) => {
        return _createVNode("div", {
          key: o,
          class: "text item"
        }, _toDisplayString('列表内容 ' + o), 1)
      }), 64))
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx } = Vue

function render (_ctx, _cache) {
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createBlock(_component_el_card, { class: "box-card" }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_Fragment, null, _renderList(4, (o) => {
        return _createVNode("div", {
          key: o,
          class: "text item"
        }, _toDisplayString('列表内容 ' + o), 1)
      }), 64))
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, createVNode: _createVNode, toDisplayString: _toDisplayString, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_card = _resolveComponent("el-card")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_component_el_row, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_Fragment, null, _renderList(2, (o, index) => {
        return _createVNode(_component_el_col, {
          span: 8,
          key: o,
          offset: index > 0 ? 2 : 0
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_card, { "body-style": { padding: '0px' } }, {
              default: _withCtx(() => [
                _createVNode("img", {
                  src: "https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png",
                  class: "image"
                }),
                _createVNode("div", { style: {"padding":"14px"} }, [
                  _createVNode("span", null, "好吃的汉堡"),
                  _createVNode("div", { class: "bottom clearfix" }, [
                    _createVNode("time", { class: "time" }, _toDisplayString(_ctx.currentDate), 1),
                    _createVNode(_component_el_button, {
                      type: "text",
                      class: "button"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("操作按钮")
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["offset"])
      }), 64))
    ]),
    _: 1
  }))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      let currentDate = ref(new Date())
      return { currentDate }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_card = _resolveComponent("el-card")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 12 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, { shadow: "always" }, {
            default: _withCtx(() => [
              _createTextVNode(" 总是显示 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, { shadow: "hover" }, {
            default: _withCtx(() => [
              _createTextVNode(" 鼠标悬浮时显示 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 8 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, { shadow: "never" }, {
            default: _withCtx(() => [
              _createTextVNode(" 从不显示 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  